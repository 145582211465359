import { inject, Injectable } from "@angular/core"
import { UtilsService } from "./utils.service"
import { UtilEnvironmentService } from "util/environment"

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private utils = inject(UtilsService)
  private environmentService = inject(UtilEnvironmentService)

  get newFilePath(): string {
    return "/images/" + this.utils.createId()
  }

  getFileUrl(filePath: string | undefined): string {
    // if (filePath) {
    //   return environment.imagekit_urlEndpoint + filePath
    // }
    const storageBucket = this.environmentService.getEnvironment().storageBucket
    if (storageBucket && filePath) {
      return "https://storage.googleapis.com/" + storageBucket + filePath
    }
    return ""
  }

}
