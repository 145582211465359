import { Component, computed, effect, inject, Renderer2, signal } from "@angular/core"
import { RouterLink, RouterModule } from "@angular/router"
import { FormsModule } from "@angular/forms"
import { PageToolbarComponent } from "./components/page-toolbar.component"
import { MyAccountComponent } from "./components/my-account/my-account.component"
import { ContentManagerRowsComponent } from "./components/content-manager/content-manager-rows.component"
import { ModerationRowsComponent } from "./components/moderation/moderation-rows.component"
import { faCircleExclamation, faEye, faEyeSlash, faMoon, faSun, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { ProfileService } from "./components/profile/profile.service"
import { RouteService } from "./services/route.service"
import { EditorComponent } from "./components/editor/editor.component"
import { PublishedDialogComponent } from "./components/dialogs/published.dialog.component"
import { TourComponent } from "./components/tour/tour.component"
import { EditorLoadService } from "./components/editor/services/editor-load.service"
import { StoryDetailsComponent } from "./components/pages/story-details.component"
import { AutoFocusDirective, NextSectionButtonComponent } from "@shared"
import { UiAlertComponent } from "ui/alert"
import { UiToastComponent } from "ui/toast"
import { FaIconComponent } from "@fortawesome/angular-fontawesome"
import { UiDialogComponent } from "ui/dialog"
import { EditorService } from "./components/editor/services/editor.service"
import { UiIconComponent } from "ui/icon"
import { UiInheritDirective } from "ui/inherit"
import { UiThemeService } from "ui/theme"
import { DataAccessFirebaseAuthService } from "data-access/firebase-auth"
import { ContentManagerComponent } from "./components/content-manager/content-manager.component"

@Component({
  imports: [
    RouterModule,
    PageToolbarComponent,
    MyAccountComponent,
    ContentManagerRowsComponent,
    ModerationRowsComponent,
    EditorComponent,
    PublishedDialogComponent,
    StoryDetailsComponent,
    TourComponent,
    FormsModule,
    AutoFocusDirective,
    UiInheritDirective,
    UiAlertComponent,
    UiToastComponent,
    FaIconComponent,
    UiDialogComponent,
    UiIconComponent,
    NextSectionButtonComponent,
    RouterLink,
    ContentManagerComponent,
  ],
  standalone: true,
  template: `
    <!--    <lib-ui-alert>-->
    <!--      <ng-container ngProjectAs="icon">-->
    <!--        <lib-ui-icon [icon]="faCircleExclamation"/>-->
    <!--      </ng-container>-->
    <!--    </lib-ui-alert>-->
    <!--    <p-messages key="top-level" position="top-center"/>-->
    <!--    < p-toast key="top-level" position="top-center"/>-->
    <!--    < p-confirmPopup key="top-level"/>-->
    @if (showIsPublished_dialog()) {
      <!--      <e2e-published-modal (confirmed)="hideIsPublished_dialog()" inherit/>-->
    }

    @if (userInit()) {
      @if (!loggedIn() && !firewallAuth()) {
        <div class="flex justify-center items-center w-full h-screen space-x-2">
          <div>
            <input
              class="input input-bordered"
              libAutoFocus
              [type]="passwordType()"
              [ngModel]="password()"
              (ngModelChange)="inputEvent($event)"
              placeholder='super secret password'
            >
          </div>
          <button
            class="btn btn-sm secondary"
            (click)="passwordVisible.set(!passwordVisible())"
          >
            submit
          </button>
        </div>
      }
    }

    @if (loggedIn() || firewallAuth()) {

      <e2e-page-toolbar/>

      <router-outlet/>

      @if (timelineSection()) {
        <button routerLink="/stories-map" style="width: 100%; background: #f3f3f3; position: fixed; bottom: 0">
          <lib-next-section-button/>
        </button>
      }

      <e2e-tour inherit/>

      @defer (when isEditor()) {
        @if (isEditor() && contentManager_dialog() && section().MANAGER) {
          <e2e-content-manager inherit/>
        }
      }

      @defer (when isModerator()) {
        @if (contentModeration_dialog() && section().MODERATION) {
          <e2e-moderation-rows inherit/>
        }
      }

      @if (showMyAccount()) {
        <e2e-my-account inherit/>
      }

    }

    <!--    <lib-theme-switcher [enable]="true"/>-->
    <!--    <lib-ui-toast></lib-ui-toast>-->

    <!--
        <lib-ui-dialog [open]="showActiveReview_dialog().content" (close)="cancelActiveReview()">
          <div ngProjectAs="title">
            Please Confirm
          </div>
          <div ngProjectAs="content">
            <div class="column" style="align-items: center">
              <div>
               {{ showActiveReview_dialog().message }}
              </div>
              <div class="row">
                <button
                  class="btn btn-sm"
                  (click)="cancelActiveReview()"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-sm"
                  (click)="startActiveReview(showActiveReview_dialog().content)"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </lib-ui-dialog>
    -->

  `,
  selector: "e2e-root",
})
export class AppComponent {
  private profileService = inject(ProfileService)
  private routeService = inject(RouteService)
  private editorLoadService = inject(EditorLoadService)
  private renderer = inject(Renderer2)
  private authStateService = inject(DataAccessFirebaseAuthService)
  private themeService = inject(UiThemeService)
  private editorService = inject(EditorService)

  contentManager_dialog = this.routeService.contentManager_dialog
  contentModeration_dialog = this.routeService.contentModeration_dialog
  timelineSection = computed(() => this.routeService.section().TIMELINE)

  showIsPublished_dialog = this.editorLoadService.showIsPublished_dialog
  routeHasPageToolbar = this.routeService.routeHasPageToolbar
  showActiveReview_dialog = this.editorLoadService.showActiveReview_dialog

  section = this.routeService.section
  loggedIn = this.authStateService.loggedIn
  userInit = this.authStateService.userInit
  isEditor = this.profileService.isEditor
  isModerator = this.profileService.isModerator

  password = signal<string | undefined>(undefined)
  passwordVisible = signal(false)
  passwordIcon = computed(() => (this.passwordVisible() ? faEye : faEyeSlash))
  passwordType = computed(() => (this.passwordVisible() ? "text" : "password"))
  firewallAuth = signal(false)

  showMyAccount = computed(() =>
    this.section().LOGIN || this.section().PROFILE || this.section().MY_CONTENT
  )

  themes = [
    "redline_light",
    "redline_dark",
    "light",
    "dark",
    "cupcake",
    "bumblebee",
    "emerald",
    "corporate",
    "synthwave",
    "retro",
    "cyberpunk",
    "valentine",
    "halloween",
    "garden",
    "forest",
    "aqua",
    "lofi",
    "pastel",
    "fantasy",
    "wireframe",
    "black",
    "luxury",
    "dracula",
    "cmyk",
    "autumn",
    "business",
    "acid",
    "lemonade",
    "night",
    "coffee",
    "winter",
    "dim",
    "nord",
    "sunset",
  ]

  icons = [
    faSun,
    faMoon,
    faSun,
    faMoon,
  ]


  constructor() {
    // this.themeService.setThemeNames(this.themes)
    // this.themeService.setThemeIcons(this.icons)

    this.firewallAuth.set(!!localStorage.getItem("accessKey"))
    effect(() => {
      if (
        this.routeService.section().TIMELINE &&
        this.routeService.fragments()[0] !== "view" &&
        this.routeService.fragments()[0] !== "edit"
      ) {
        this.renderer.setStyle(document.body, "user-select", "none")
      } else {
        this.renderer.removeStyle(document.body, "user-select")
      }
    })
  }

  inputEvent(event: string): void {
    if (event.toLowerCase() === "supersecretpassword" || event === "ssp") {
      localStorage.setItem("accessKey", "true")
      this.firewallAuth.set(true)
    }
  }

  hideIsPublished_dialog() {
    this.editorLoadService.hideIsPublished_dialog()
  }

  protected readonly faCircleExclamation = faCircleExclamation
  protected readonly faTrashCan = faTrashCan
}
