import { Component, inject } from "@angular/core"
import { IntroService } from "./intro.service"
import { IntroLogoComponent } from "./header/intro-logo.component"
import { IntroBrandHeaderComponent } from "./header/intro-brand-header.component"
import { IntroGradeComponent } from "./grades/intro-grade.component"
import { IntroLegacyCardComponent } from "./legacy/intro-legacy-card.component"
import { IntroLegacyComponent } from "./legacy/intro-legacy.component"
import { IntroGradesComponent } from "./grades/intro-grades.component"
import { IntroVimeoVideoComponent } from "./intro-vimeo-video.component"
import { IntroVirtualTourCtaComponent } from "./intro-virtual-tour-cta.component"
import { IntroDefinitionsComponent } from "./definitions/intro-definitions.component"
import { RouterLink } from "@angular/router"
import { IntroDefinitionsMoreComponent } from "./definitions/intro-definitions-more.component"
import { PageToolbarService } from "../../page-toolbar.service"
import { environment } from "../../../../environments/environment"
import { NgOptimizedImage } from "@angular/common"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    IntroLogoComponent,
    IntroBrandHeaderComponent,
    IntroGradeComponent,
    IntroLegacyCardComponent,
    IntroLegacyComponent,
    IntroGradesComponent,
    IntroVimeoVideoComponent,
    IntroVirtualTourCtaComponent,
    IntroDefinitionsComponent,
    RouterLink,
    UiInheritDirective,
    IntroDefinitionsMoreComponent,
    NgOptimizedImage,
  ],
  providers: [],
  standalone: true,
  template: `
    <div #pageToolbarPlaceholder class="w-full" [style.height.px]="pageToolbarHeight()"></div>
    <div class="column bg-base-100 relative">
      <div class="pl-10">
        <div class="border-l-8 border-dashed border-primary">
          <div class="row gap-10 p-10">
            <div class="column gap-5">
              @for (introLogo of logos(); track index; let index = $index) {
                <e2e-intro-logo [introLogo]="introLogo" inherit/>
              }
            </div>
            <e2e-intro-brand-header inherit/>
          </div>
        </div>
      </div>
      <div class="pl-10 relative" [style.background]="redlineDesignedBackgroundImage">
        <div class="border-l-8 border-dashed border-primary-content">
          <div class="row gap-0 items-stretch">
            <div class="min-w-80 mr-10 column text-primary-content font-black leading-5 text-2xl" style="flex: 4">

              <div class="border-b-8 border-dashed border-primary-content p-10">
                <div>
                  REDLINING DESIGNED
                </div>
                <div>
                  STRUCTURAL RACISM INTO OUR NEIGHBORHOODS...
                </div>
              </div>
              <div class="border-r-8 border-dashed border-primary-content p-10 flex-1">
                <div class="center">
                  IT HAS NEVER BEEN UNDESIGNED.
                </div>
                <div class="pt-24 pr-14">
                  REDLINING EMBODIES A PROCESS THAT TRANSFORMED EXPLICIT RACISM INTO STRUCTURAL RACISM.
                  THESE STRUCTURAL CONDITIONS LARGELY REMAIN TODAY.
                </div>
              </div>

            </div>

            <div class="min-w-40 -ml-24 py-10" style="flex: 2">
              <img [ngSrc]="'/assets/pages/intro/map-image.png'" alt="Map Image" fill style="position: relative"/>
            </div>
          </div>
        </div>
        <e2e-intro-virtual-tour-cta inherit/>
      </div>
      <div class="pl-10">
        <div class="border-l-8 border-dashed border-primary">
          <div class="row gap-5 p-10">
            <!--       
               background-size: cover; background-position: bottom; background-repeat: no-repeat;
               [style.background-image]="legacyBackgroundImage"-->
            <div style="flex: 1">
              <e2e-intro-definitions inherit/>
            </div>
            <div style="flex: 1">
              <e2e-intro-grades inherit/>
            </div>
          </div>
          <e2e-intro-definitions-more inherit/>
        </div>
      </div>
      <!--      <e2e-intro-vimeo-video ></e2e-intro-vimeo-video>-->
      <div
        class="pl-10"
        style="background: bottom / cover no-repeat"
        [style.background-image]="backgroundImage"
      >
        <div class="border-l-8 border-dashed border-primary">
          <e2e-intro-legacy inherit/>
        </div>
      </div>
    </div>
  `,
  selector: "e2e-intro",
})
export class IntroComponent {
  private introService = inject(IntroService)
  private pageToolbarService = inject(PageToolbarService)
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight

  logos = this.introService.logos
  mapImage = "url('" + environment.imagekit_urlEndpoint + "/assets/pages/intro/map-image.png')"
  backgroundImage = "url('" + environment.imagekit_urlEndpoint + "/assets/pages/intro/legacy/legacy-background-image.jpg')"

  redlineDesignedBackgroundImage = "url('" + environment.imagekit_urlEndpoint + "/assets/pages/intro/background.jpg') no-repeat bottom / cover"
  legacyBackgroundImage = "url('" + environment.imagekit_urlEndpoint + "/assets/pages/intro/legacy/legacy-background-image.jpg')"

  constructor() {
    // effect(() => this.pageToolbarHeight()) // required in order to trigger template refresh when pageToolbarHeight changes
  }
}
