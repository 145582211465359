import { Component, computed, EventEmitter, inject, input, output, Output, signal } from "@angular/core"
import {
  AnyLayer,
  EventData,
  FillPaint,
  GeoJSONSourceOptions,
  Map,
  MapboxEvent,
  MapLayerMouseEvent,
  MapMouseEvent,
  SymbolLayout,
  SymbolPaint,
} from "mapbox-gl"
import { RegionProperties, StoryFeature, StoryProperties } from "./map.type"
import { LngLat, Story } from "../content/content.model"
import { NgxMapboxGLModule } from "ngx-mapbox-gl"
import { MapService } from "./map.service"
import { regionData, RegionEnum } from "../../regions/region.model"
import { RouteService } from "../../services/route.service"
import { FeatureCollection, Geometry } from "geojson"
import { GeoJSONSourceComponent } from "ngx-mapbox-gl/lib/source/geojson/geojson-source.component"
import { StorageService } from "@shared"
import { faMapPin } from "@fortawesome/pro-solid-svg-icons/faMapPin"
import { UiIconComponent } from "ui/icon"
import { UtilAnalyticsService } from "util/analytics"
import { SectionEnum } from "../shared/fields/fields.type"

type GeoJsonRegion = {
  sourceId: GeoJSONSourceComponent["id"]
  sourceDataUrl: GeoJSONSourceOptions["data"]
  layerId: AnyLayer["id"]
}

@Component({
  imports: [
    NgxMapboxGLModule,
    UiIconComponent,
  ],
  standalone: true,
  template: `
    <mgl-map
      [style]="mapStyle"
      [zoom]="[zoomLevel()]"
      [bearing]="[0]"
      [pitch]="[45]"
      [center]="center()"
      [scrollZoom]="scrollZoom()"
      [style.height.%]="100"
      (mapLoad)="loadMap($event)"
      (zoomEnd)="zoomEvent($event)"
      (mapClick)="onMapClick($event)"
    >
      <mgl-image id="black-marker" [url]="mapMarkerBlack()"/>
      <mgl-image id="blue-marker" [url]="mapMarkerBlue()"/>
      <mgl-image id="green-marker" [url]="mapMarkerGreen()"/>
      <mgl-image id="red-marker" [url]="mapMarkerRed()"/>
      <mgl-image id="outline-red-marker" [url]="mapMarkerRedOutline()"/>
      
      @for (geoJsonRegion of geoJsonRegions(); track geoJsonRegion.sourceId) {
        <mgl-geojson-source
          [id]="geoJsonRegion.sourceId"
          [data]="geoJsonRegion.sourceDataUrl"
        />
        <mgl-layer
          [id]="geoJsonRegion.layerId"
          type="fill"
          [source]="geoJsonRegion.sourceId"
          [paint]="fillPaint"
          (layerClick)="onRegionClick($event)"
        />
      }

      <!--
            @if (!regionPopup()?.properties?.clarifying_remarks && regionPopup(); as popup) {
              <mgl-popup [lngLat]="popup.lngLat">
                &lt;!&ndash;          {{ popup.properties.location || popup.properties.name }}&ndash;&gt;
                {{ popup.properties.security_grade }}
                {{ popup.properties.area_number }}
                &lt;!&ndash;          {{ popup.properties.date }}&ndash;&gt;
                &lt;!&ndash;          {{ popup.properties.clarifying_remarks }}&ndash;&gt;
                @if (isAdmin()) {
                  <pre>
                    {{ popup.properties | json }}
                  </pre>
                }
              </mgl-popup>
            }
      -->

      <!-- custom markers -->
      @if (showMarkers() && markerLocation(); as location) {

        <mgl-marker [lngLat]="location" anchor="bottom">
          <div
            style="font-size: 48px; color: red; transform: rotate(30deg) translateX(10px)"
          >
            <lib-ui-icon [icon]="faMapPin"/>
          </div>
        </mgl-marker>
      }

      <!-- content markers and clusters -->
      @if (showMarkers() && story()) {
        <mgl-geojson-source
          id="story-markers-source"
          [data]="storyMarkerCollection()"
        />
        <mgl-layer
          id="story-markers"
          type="symbol"
          source="story-markers-source"
          [layout]="markerLayoutRed"
          [paint]="markerPaint"
          [minzoom]="0"
          [maxzoom]="24"
          (layerClick)="onContentMarkerClick($event)"
        />
      }
      @if (showMarkers() && !story()) {
        <mgl-geojson-source
          id="my-markers-source"
          [data]="myStoriesMarkerCollection()"
        />
        <mgl-layer
          id="my-markers"
          type="symbol"
          source="my-markers-source"
          [layout]="markerLayoutRedOutline"
          [paint]="markerPaint"
          [minzoom]="0"
          [maxzoom]="24"
          (layerClick)="onContentMarkerClick($event)"
        />
        <mgl-geojson-source
          id="published-markers-source"
          [data]="publishedStoriesMarkerCollection()"
        />
        <mgl-layer
          id="published-markers"
          type="symbol"
          source="published-markers-source"
          [layout]="markerLayoutRed"
          [paint]="markerPaint"
          [minzoom]="0"
          [maxzoom]="24"
          (layerClick)="onContentMarkerClick($event)"
        />

      }
      <!-- <mgl-layer
      id="clusters"
      type="circle"
      [filter]="['has', 'point_count']"
      [source]="markerSource"
      [paint]="clusterPaint"
      />
    <mgl-layer
      id="clusterLabels"
      type="symbol"
      [filter]="['has', 'point_count']"
      [source]="markerSource"
      [layout]="clusterLabelLayout"
      [paint]="clusterLabelPaint"
      /> -->
      <!-- <mgl-popup
      *ngIf="regionProperties"
      [lngLat]="regionProperties.lngLat"
      >
      <h3>
        {{ regionProperties.properties.text }}
      </h3>
    </mgl-popup> -->
    </mgl-map>
  `,
  selector: "e2e-mapbox-map",
})
export class MapMapboxComponent {
  private localMapService = inject(MapService)
  private storageService = inject(StorageService)
  private routeService = inject(RouteService)
  private analyticsService = inject(UtilAnalyticsService)

  showMarkers = input(false)
  markerLocation = input<LngLat | undefined>(undefined)
  story = input<Story | undefined>(undefined)
  storyZoomLevel = input<number | undefined>(undefined, { alias: "zoomLevel" })
  scrollZoom = input(false)
  zoomLevelChange = output<number>()
  mapClick = output<LngLat>()
  contentMarkerClick = output<StoryProperties>()
  regionClick = output<{ lngLat: LngLat; properties: RegionProperties | undefined }>()

  mapMarkerBlack = signal(this.storageService.getFileUrl("/assets/images/map-marker.png"))
  mapMarkerBlue = signal(this.storageService.getFileUrl("/assets/images/map-marker-blue.png"))
  mapMarkerGreen = signal(this.storageService.getFileUrl("/assets/images/map-marker-green.png"))
  mapMarkerRed = signal(this.storageService.getFileUrl("/assets/images/map-marker-red.png"))
  mapMarkerRedOutline = signal(this.storageService.getFileUrl("/assets/images/map-marker-red-outline.png"))

  debugDialogVisible = signal(false)
  dialogVisible = signal(false)
  zoomLevel = computed(() => this.storyZoomLevel() || this.localMapService.zoomLevel())
  map: Map | undefined = undefined

  geoJsonRegions = signal<GeoJsonRegion[]>(
    Object.values(regionData)
      .filter(regionData => regionData.enabled)
      .map((regionData) => ({
        sourceId: regionData.region + "-sourceId",
        sourceDataUrl: this.storageService.getFileUrl("/assets/geojson/" + regionData.geoJson),
        layerId: regionData.region + "-layerId",
      }))
      .filter((geoJsonRegions) => {
        if (this.routeService.debug()) console.log(geoJsonRegions)
        return true
      }),
  ).asReadonly()

  center = this.localMapService.center

  mapStyle = "mapbox://styles/bradencrooks/clgmf26xr000v01qnfx5gbtwt"

  fillPaint: FillPaint = {
    "fill-color": [
      "case",
      ["==", ["get", "grade"], "A"],
      ["rgba", 121, 255, 97, 0.3],
      ["==", ["get", "grade"], "B"],
      ["rgba", 97, 194, 255, 0.3],
      ["==", ["get", "grade"], "C"],
      ["rgba", 255, 239, 97, 0.3],
      ["rgba", 255, 97, 97, 0.3],
    ],
    "fill-outline-color": [
      "case",
      ["==", ["get", "grade"], "A"],
      ["rgb", 121, 255, 97],
      ["==", ["get", "grade"], "B"],
      ["rgb", 97, 194, 255],
      ["==", ["get", "grade"], "C"],
      ["rgb", 255, 239, 97],
      ["rgb", 255, 97, 97],
    ],
  }
  markerLayoutBlack: SymbolLayout = {
    "icon-image": "black-marker",
    "icon-size": 1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerLayoutBlue: SymbolLayout = {
    "icon-image": "blue-marker",
    "icon-size": 1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerLayoutGreen: SymbolLayout = {
    "icon-image": "green-marker",
    "icon-size": 1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerLayoutRed: SymbolLayout = {
    "icon-image": "red-marker",
    "icon-size": 1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerLayoutRedOutline: SymbolLayout = {
    "icon-image": "outline-red-marker",
    "icon-size": 1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerPaint: SymbolPaint = {
    // "icon-color": "green",
    // "icon-halo-color": "green",
    // "text-halo-width": 1,
    // "text-halo-blur": 3,
    // "text-halo-color": "white",
  }
  /*
    clusterPaint: mapboxgl.CirclePaint = {
      "circle-color": "#FAA",
      "circle-radius": 24,
    }
    clusterLabelLayout: mapboxgl.SymbolLayout = {
      "text-field": ["get", "point_count"],
    }
    clusterLabelPaint: mapboxgl.SymbolPaint = {
      "text-color": "#000",
    }
  */

  pinMarkerPoint = signal<Geometry | null>(null)
  // regionProperties: { lngLat: mapboxgl.LngLatLike, properties: StoryProperties } | null = null
  regionProperties = this.localMapService.regionProperties
  storyMarkerCollection = computed<FeatureCollection>(() => {
    const story_input = this.story()
    const storyMarkerFeature = this.getFeatureFromStory(story_input)
    return (
      (story_input &&
        storyMarkerFeature && {
          type: "FeatureCollection",
          features: [storyMarkerFeature],
        }) || {
        type: "FeatureCollection",
        features: [],
      }
    )
    /*
        if (story_input) {
          const storyMarkerFeature = this.getFeatureFromStory(story_input)
          if (storyMarkerFeature) {
            return {
              type: "FeatureCollection",
              features: [storyMarkerFeature]
            }
          }
        }
        return {
          type: "FeatureCollection",
          features: this.localMapService.discoverMarkers()
            .map(story => this.getFeatureFromStory(story))
            .filter((storyMarkerFeature): storyMarkerFeature is StoryMarkerFeature => Boolean(storyMarkerFeature))
        }
    */
  })
  publishedStoriesMarkerCollection = computed<FeatureCollection>(() => ({
    type: "FeatureCollection" as FeatureCollection["type"],
    features: this.localMapService
      .publishedMapStories()
      .map((story) => this.getFeatureFromStory(story))
      .filter((storyMarkerFeature): storyMarkerFeature is StoryFeature => Boolean(storyMarkerFeature)),
  }))
  myStoriesMarkerCollection = computed<FeatureCollection>(() => ({
    type: "FeatureCollection" as FeatureCollection["type"],
    features: this.localMapService
      .myMapStories()
      .map((story) => this.getFeatureFromStory(story))
      .filter((storyMarkerFeature): storyMarkerFeature is StoryFeature => Boolean(storyMarkerFeature)),
  }))

  constructor() {
    this.analyticsService.sendScreenViewEvent(this.constructor.name, this.constructor.name)
  }

  // create a feature based on content
  getFeatureFromStory(story: Story | undefined): StoryFeature | undefined {
    const location = story?.settings.location
    if (location?.lng && location?.lat && story) {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [location.lng, location.lat],
        },
        properties: {
          authorUserName: story.created.userName,
          storyId: story.id,
          text: story[SectionEnum.BODY].value,
        },
      }
    }
    return
  }

  onMapClick(event: MapMouseEvent): void {
    /**
     * TODO: validate event data with zod interface
     * event.lngLat
     */
    this.mapClick.emit(event.lngLat)
    this.pinMarkerPoint.set({
      type: "Point",
      coordinates: [event.lngLat.lng, event.lngLat.lat],
    })
  }

  onContentMarkerClick(event: MapLayerMouseEvent): void {
    /**
     * TODO: validate event data with zod interface
     * event.features[0].properties as StoryProperties
     */
    const storyProperties = event.features?.[0]?.properties as StoryProperties | undefined
    if (storyProperties) {
      this.contentMarkerClick.emit(storyProperties)
    }
  }

  onRegionClick(event: MapLayerMouseEvent): void {
    /**
     * TODO: validate event data with zod interface
     * event.lngLat
     * event.features?.[0].properties as RegionProperties
     */
    const lngLat = event.lngLat
    const properties = event.features?.[0].properties as RegionProperties | undefined
    this.regionClick.emit({ lngLat, properties })

  }

  loadMap(mapboxEvent: MapboxEvent<undefined> & EventData) {
    const map = mapboxEvent.target
    this.localMapService.setZoomLevel(mapboxEvent.target.getZoom())
    this.map = map
  }

  zoomEvent(event: EventData) {
    const zoomLevel = this.map?.getZoom()
    if (zoomLevel !== undefined) {
      this.zoomLevelChange.emit(zoomLevel)
      // this.localMapService.setZoomLevel(zoomLevel)
    }
  }

  protected readonly faMapPin = faMapPin
}
