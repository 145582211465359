import { Component, computed, input } from "@angular/core"
import { Content } from "../../../content/content.model"
import { ImageViewComponent } from "../../../shared/fields/image/image-view.component"
import { FieldType, Image, ImageView, SectionEnum } from "../../../shared/fields/fields.type"
import { TextComponent } from "../../../shared/fields/text.component"
import { DragClickBlockerDirective } from "../../../../directives/drag-click-blocker.directive"
import { UiInheritDirective } from "ui/inherit"
import { TextAreaView } from "./timeline-item.component"

@Component({
  imports: [
    ImageViewComponent,
    TextComponent,
    UiInheritDirective,
    DragClickBlockerDirective,
  ],
  standalone: true,
  template: `
    <div style="position: relative; min-height: 25px">
      <div
        [style.left.px]="content().settings.position.left.px"
        [style.top.px]="content().settings.position.top.px"
      >
        <div class="column" style="gap: 0; outline: none; position: relative; justify-content: center">
          @if (titleValue()) {
            <e2e-text
              [textStyles]="titleTextStyles()"
              [value]="titleValue()"
              inherit
            />
          }
          @if (subTitleValue()) {
            <e2e-text
              [textStyles]="subTitleTextStyles()"
              [value]="subTitleValue()"
              inherit
            />
          }
          @if (bodyValue()) {
            <e2e-text
              [textStyles]="bodyTextStyles()"
              [value]="bodyValue()"
              inherit
            />
          }
          @if (imageView(); as imageView) {
            @if (imageView.filePath || imageView.svgGraphics) {
              <e2e-image-view
                [imageView]="imageView"
                inherit
              />
            }
          }
          @if (noteValue()) {
            <e2e-text
              [textStyles]="noteTextStyles()"
              [value]="noteValue()"
              inherit
            />
          }
        </div>
      </div>
    </div>
  `,
  selector: "e2e-timeline-item-featured",
})
export class TimelineItemFeaturedComponent {

  content = input.required<Content>()
  textArea_map = input.required<Map<SectionEnum, TextAreaView>>()

  titleTextStyles = computed(() => this.textArea_map().get(SectionEnum.TITLE)?.textStyles || {})
  titleValue = computed(() => this.textArea_map().get(SectionEnum.TITLE)?.value || "")
  subTitleTextStyles = computed(() => this.textArea_map().get(SectionEnum.SUBTITLE)?.textStyles || {})
  subTitleValue = computed(() => this.textArea_map().get(SectionEnum.SUBTITLE)?.value || "")
  bodyTextStyles = computed(() => this.textArea_map().get(SectionEnum.BODY)?.textStyles || {})
  bodyValue = computed(() => this.textArea_map().get(SectionEnum.BODY)?.value || "")
  noteTextStyles = computed(() => this.textArea_map().get(SectionEnum.NOTE)?.textStyles || {})
  noteValue = computed(() => this.textArea_map().get(SectionEnum.NOTE)?.value || "")

  image_map = computed<Map<SectionEnum, ImageView>>(
    () =>
      new Map(
        Object.values(this.content().rows)
          .filter((row) => row.field === FieldType.IMAGE)
          .map((row) => [
            row.section,
            {
              ...(row as Image),
              contentWidth: this.content().settings.width.px,
            },
          ]),
      ),
  )
  imageView = computed(() => this.image_map().get(SectionEnum.IMAGE))
}
