import { Component, computed, inject, input, output } from "@angular/core"
import { Image, ImageView, SectionEnum } from "../shared/fields/fields.type"
import { ImageEditComponent } from "../shared/fields/image/image-edit.component"
import { TextAreaEditComponent } from "../shared/fields/text-area/text-area-edit.component"
import { EditorContext, EditorService } from "./services/editor.service"
import { ContentType, reviewFeedbackData } from "../content/content.model"
import { TextComponent } from "../shared/fields/text.component"
import { EditorFeedbackComponent } from "./fields/editor-feedback.component"
import { ImageViewComponent } from "../shared/fields/image/image-view.component"
import { EditorMapComponent } from "./fields/editor-map.component"
import { FirestoreReadService } from "../content/firestore-read.service"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    ImageEditComponent,
    TextAreaEditComponent,
    TextComponent,
    ImageViewComponent,
    UiInheritDirective,
    EditorFeedbackComponent,
    EditorMapComponent,
  ],
  standalone: true,
  template: `
    <div class="column">

      @if (adminContext()) {
        <e2e-text-area-edit [rowId]="titleRowId()" context="admin" inherit/>
        <e2e-text-area-edit [rowId]="subTitleRowId()" context="admin" inherit/>
        <e2e-text-area-edit [rowId]="bodyRowId()" context="admin" inherit/>
        <e2e-text-area-edit [rowId]="noteRowId()" context="admin" inherit/>
        <e2e-image-edit [rowId]="imageRowId()" context="admin" inherit/>
        <e2e-editor-map inherit/>
      }

      @if (contentContext()) {
        <e2e-text-area-edit [rowId]="titleRowId()" inherit/>
        <e2e-text-area-edit [rowId]="bodyRowId()" inherit/>
        <e2e-image-edit [rowId]="imageRowId()" inherit/>
        <e2e-editor-map/>
      }

      @if (contributorContext()) {
        @if (localMap()) {
          <e2e-text-area-edit [rowId]="titleRowId()" inherit/>
          <e2e-text-area-edit [rowId]="bodyRowId()" inherit/>
          <e2e-image-edit [rowId]="imageRowId()" inherit/>
        }

        @if (timeline()) {
          <e2e-text-area-edit [rowId]="titleRowId()" inherit/>
          <e2e-text-area-edit [rowId]="bodyRowId()" inherit/>
          <e2e-image-edit [rowId]="imageRowId()" inherit/>
        }

        <e2e-editor-map inherit/>

        @if (needsChanges()) {
          <div>
            Reviewer Feedback:
          </div>
          @for (reviewFeedback of reviewFeedbacks(); track reviewFeedback) {
            <div>
              {{ reviewFeedback?.description }}
            </div>
          }
        }
      }

      @if (moderationContext()) {
        @if (linkTitles().length) {
          <div>
            In response to:
            @for (linkTitle of linkTitles(); track linkTitle) {
              <div>
                {{ linkTitle }}
              </div>
            }
          </div>
        }

        <div>
          Authored by:
          <div>
            {{ author() }}
          </div>
        </div>

        @if (localMap()) {
          Body Text:
          <textarea class="textarea textarea-bordered" [rows]="6" readonly [value]="body()"></textarea>
        }

        @if (timeline()) {

          Title:
          <textarea class="textarea textarea-bordered" [rows]="1" readonly [value]="title()"></textarea>
          Body:
          <textarea class="textarea textarea-bordered" [rows]="6" readonly [value]="body()"></textarea>

          @if (imageView(); as imageView) {
            Image:
            <e2e-image-view [imageView]="imageView" inherit/>
          }
        }

        <e2e-editor-map inherit/>

        <div>
          Reviewer Feedback Options:
        </div>

        <e2e-editor-feedback inherit/>

      }

    </div>
  `,
  selector: "e2e-editor-content",
})
export class EditorContentComponent {
  private editorService = inject(EditorService)
  private firestoreReadService = inject(FirestoreReadService)

  context = input.required<EditorContext>()

  // close = output()
  // resubmitForReview = output()
  // approveAndClose = output()

  adminContext = computed(() => this.context() === "admin")
  contentContext = computed(() => this.context() === "content")
  contributorContext = computed(() => this.context() === "contributor")
  moderationContext = computed(() => this.context() === "moderation")

  linkTitles = computed<string[]>(() => {
    const linkIds = this.editorService.editorContent()?.settings.links || []
    return linkIds
      .map(linkId => this.firestoreReadService.stories().get(linkId)?.[SectionEnum.TITLE].value || "")
      .filter(Boolean)
  })

  localMap = computed(() => this.editorService.editorContent()?.status.contentTypes.includes(ContentType.LOCAL_MAP))
  timeline = computed(() => this.editorService.editorContent()?.status.contentTypes.includes(ContentType.TIMELINE))

  story = computed(() => {
    const content = this.editorService.editorContent()
    return content && this.firestoreReadService.storyFromContent(content)
  })

  author = computed(() => this.story()?.created.userName)
  title = computed(() => this.story()?.[SectionEnum.TITLE].value)
  titleRowId = computed(() => this.story()?.[SectionEnum.TITLE].rowId || "")
  subTitle = computed(() => this.story()?.[SectionEnum.SUBTITLE].value)
  subTitleRowId = computed(() => this.story()?.[SectionEnum.SUBTITLE].rowId || "")
  body = computed(() => this.story()?.[SectionEnum.BODY].value)
  bodyRowId = computed(() => this.story()?.[SectionEnum.BODY].rowId || "")
  note = computed(() => this.story()?.[SectionEnum.NOTE].value)
  noteRowId = computed(() => this.story()?.[SectionEnum.NOTE].rowId || "")

  imageView = computed<ImageView | undefined>(() => {
    const image = this.editorService.editorContent()?.rows?.[this.imageRowId()] as Image | undefined
    return image && {
      ...image,
      contentWidth: this.editorService.editorContent()?.settings.width?.px || 0,
    }
  })
  imageRowId = computed(() => this.story()?.[SectionEnum.IMAGE].rowId || "")

  saving = this.editorService.saving
  preSaving = this.editorService.preSaving
  needsChanges = this.editorService.needsChanges
  reviewFeedbacks = computed(() => {
    const content = this.editorService.editorContent()
    return (
      (content &&
        content.status.reviewFeedbacks.map((reviewFeedback) =>
          reviewFeedbackData.find((data) => data.identifier === reviewFeedback),
        )) ||
      []
    )
  })

}
