import { Component, computed, inject, input, signal } from "@angular/core"
import { CdkDrag, CdkDragEnd } from "@angular/cdk/drag-drop"
import { Classification, Content, ContentType, PublishStatus, Story } from "../../../content/content.model"
import { BookmarkComponent } from "@shared"
import { Image, SectionEnum, TextArea, TextAreaStyle, TextStyles } from "../../../shared/fields/fields.type"
import { TimelineService } from "../timeline.service"
import { RouteService } from "../../../../services/route.service"
import { debounceSignal } from "@ddtmm/angular-signal-generators"
import { EditorService } from "../../../editor/services/editor.service"
import { FirestoreReadService } from "../../../content/firestore-read.service"
import { DragClickBlockerDirective } from "../../../../directives/drag-click-blocker.directive"
import { FormsModule } from "@angular/forms"
import { faTag, faTags } from "@fortawesome/pro-solid-svg-icons"
import { UiIconComponent } from "ui/icon"
import { UiInheritDirective } from "ui/inherit"
import { EditorComponent } from "../../../editor/editor.component"
import { StoryDetailsComponent } from "../../story-details.component"
import { ProfileService } from "../../../profile/profile.service"
import { NgTemplateOutlet } from "@angular/common"
import { TimelineItemNationalComponent } from "./timeline-item-national.component"
import { TimelineItemRegionalComponent } from "./timeline-item-regional.component"
import { TimelineItemFeaturedComponent } from "./timeline-item-featured.component"
import { FieldsService } from "../../../shared/fields/fields.service"
import { ProfileProgress } from "../../../profile/profile.model"

export type TextAreaView = { textStyles: TextStyles, value: string }

@Component({
  imports: [
    BookmarkComponent,
    CdkDrag,
    UiInheritDirective,
    DragClickBlockerDirective,
    FormsModule,
    UiIconComponent,
    EditorComponent,
    StoryDetailsComponent,
    NgTemplateOutlet,
    TimelineItemNationalComponent,
    TimelineItemRegionalComponent,
    TimelineItemFeaturedComponent,
  ],
  standalone: true,
  template: `
    <!-- not being edited  -->
    @if (!showEditor() && (editMode() || published())) {
      @if (interactiveContent()) {
        @if (showDetails()) {
          <e2e-story-details
            [contentId]="content().id"
            (close)="showDetails.set(false)"
            (createNewContent)="createNewContent()"
            inherit
          />
        }
        @if (showNewContentEditor() && newContent(); as newContent) {
          <e2e-editor
            context="contributor"
            [content]="newContent"
            [isNewContent]="true"
            (close)="showNewContentEditor.set(false)"
            inherit
          />
        }
        <input
          type="radio"
          name="contentSelect"
          [id]="content().id"
          class="absolute"
          style="opacity: 0; height: 0; width: 0;"
          [style.left.px]="contentStyles().positionLeft"
          [style.top.px]="contentStyles().positionTop"
          [value]="content().id"
          [ngModel]="selectedId"
          (ngModelChange)="changeSelectedId($event, content().id)"
          (keydown.enter)="clickContent($event)"
          (focus)=hasFocus.set(true)
          (blur)=hasFocus.set(false)
        >
        <label [for]="content().id">
          <ng-container [ngTemplateOutlet]="timelineItem"/>
        </label>
      }

      @if (!interactiveContent()) {
        <ng-container [ngTemplateOutlet]="timelineItem"/>
      }
    }

    <!-- being edited  -->
    @if (showEditor() && editMode()) {
      <e2e-editor
        context="admin"
        [content]="content()"
        [scrollableBackground]="true"
        (close)="showEditor.set(false)"
        inherit
      />
      <ng-container [ngTemplateOutlet]="timelineItem"/>"
    }

    <ng-template #timelineItem>
      <div
        class="row gap-1 flex-nowrap items-start absolute border-primary border-l-4"
        [class]="showLeftBorder() ? 'border-dashed' : 'border-transparent'"
        [style.outline-style]="unpublished() ? 'dotted' : ''"
        [style.box-shadow]="selectedBoxShadow()"
        [style.cursor]="isSvgImage() && interactiveMode() && interactiveContent() ? '' : 'pointer'"
        [style.z-index]="highlight() ? 12 : styles().zIndex"
        [style.transform]="styles().transform"
        [style.width.px]="styles().width"
        [style.left.px]="styles().positionLeft"
        [style.top.px]="styles().positionTop"
        (click)="clickContent($event)"
        (mouseover)="mouseEvent.set($event)"
        (mouseout)="mouseEvent.set($event)"
      >
        @if (nationalContent()) {
          <e2e-timeline-item-national [content]="editorContent()" [textArea_map]="textArea_map()" inherit/>
        }
        @if (regionalContent()) {
          <e2e-timeline-item-regional [content]="editorContent()" [textArea_map]="textArea_map()" inherit/>
        }
        @if (featuredContent()) {
          <e2e-timeline-item-featured [content]="editorContent()" [textArea_map]="textArea_map()" inherit/>
        }

        @if (showTag()) {
          <lib-ui-icon
            [style.transform]="flagRotationStyle()"
            [icon]="showTags() ? faTags : faTag"
            [class]="showTag() ? 'text-accent' : 'text-transparent'"
          />
        }

      </div>
    </ng-template>
  `,
  selector: "e2e-timeline-item",
})
export class TimelineItemComponent {
  private routeService = inject(RouteService)
  private timelineService = inject(TimelineService)
  private editorService = inject(EditorService)
  private firestoreReadService = inject(FirestoreReadService)
  private profileService = inject(ProfileService)
  private fieldService = inject(FieldsService)

  content = input.required<Content>()
  editorContent = computed(() => {
    const editorContent = this.editorService.editorContent()
    return editorContent && editorContent.id === this.content().id
      ? editorContent
      : this.content()
  })
  itemContent = computed(() => this.showEditor() ? this.editorContent() : this.content())

  textArea_map = computed<Map<SectionEnum, TextAreaView>>(() => {
    const story = this.firestoreReadService.storyFromContent(this.editorContent())
    const sections = [SectionEnum.BODY, SectionEnum.TITLE, SectionEnum.SUBTITLE, SectionEnum.NOTE]
    return new Map(sections
      .map(section => {
        const textArea = story[section as keyof Story] as TextArea | undefined
        const styles = this.fieldService.textAreaStyle(textArea?.style) ?? ({} as TextAreaStyle)
        const classes: Set<string> = new Set(styles.classes?.split(" "))
        switch (story.status.classification) {
          case Classification.REGIONAL:
            classes.add("text-primary-content")
            if (section === SectionEnum.BODY) {
              classes.add("bg-primary")
            }
            break
          case Classification.NATIONAL:
            classes.add("bg-base-100")
            if (story[SectionEnum.TITLE].value.toLowerCase() === "redlining") {
              classes.add("text-primary")
            }
            break
          case Classification.FEATURED:
            classes.add("bg-primary")
            classes.add("text-primary-content")
        }
        if (this.editorContent().settings.position.top.px > 690 && story.settings.position.top.px < 870) {
          classes.add("bg-primary")
          classes.add("text-primary-content")
          classes.delete("bg-base-100")
        }
        return [
          section,
          {
            textStyles: {
              ...styles,
              classes: Array.from(classes).join(" "),
              fontSize_px: styles.fontSize_px && ((styles.fontSize_px / 5) as TextStyles["fontSize_px"]),
            },
            value: textArea?.value || "",
          } as TextAreaView,
        ]
      }),
    )
    /*
          Object.values(story)
            .filter((row) => row.field === FieldType.TEXT_AREA)
            .map((row) => {
              const styles = this.fieldService.textAreaStyle((row as TextArea).style) ?? ({} as TextAreaStyle)
              const classes: Set<string> = new Set(styles.classes?.split(" "))
              switch (story.status.classification) {
                case Classification.REGIONAL:
                  classes.add("text-primary-content")
                  if (row.section === SectionEnum.BODY) {
                    classes.add("bg-primary")
                  }
                  break
                case Classification.NATIONAL:
                  classes.add("bg-base-100")
                  if (story.title.value.toLowerCase() === "redlining") {
                    classes.add("text-primary")
                  }
                  break
                case Classification.FEATURED:
                  classes.add("bg-primary")
                  classes.add("text-primary-content")
              }
              if (this.editorContent().settings.position.top.px > 510 && this.editorContent().settings.position.top.px < 660) {
                classes.add("bg-primary")
                classes.add("text-primary-content")
                classes.delete("bg-base-100")
              }
              return [
                row.section,
                {
                  ...(row as TextArea),
                  textStyles: {
                    ...styles,
                    classes: Array.from(classes).join(" "),
                    fontSize_px: styles.fontSize_px && ((styles.fontSize_px / 5) as TextStyles["fontSize_px"]),
                  },
                },
              ]
            }),
        )
    */
  })

  nationalContent = computed(() => this.content().status.classification === Classification.NATIONAL)
  regionalContent = computed(() => this.content().status.classification === Classification.REGIONAL)
  featuredContent = computed(() => this.content().status.classification === Classification.FEATURED)
  contributedContent = computed(() => this.content().status.classification === Classification.CONTRIBUTED)

  interactiveMode = this.timelineService.interactiveMode
  editMode = computed(() => !this.timelineService.interactiveMode())
  selectedId = this.timelineService.selectedId

  selected = computed(() => this.timelineService.selectedId() === this.content().id)

  published = computed(() => this.content().status.publishStatus === PublishStatus.PUBLISHED)
  unpublished = computed(() => this.content().status.publishStatus === PublishStatus.UNPUBLISHED)

  // animationFrameId = -1
  dragging = signal(false)
  highlight = signal(false)
  mouseEvent = signal<Event | undefined>(undefined)
  showTag = computed(() =>
    this.content().status.showInteractiveDetails && (this.timelineService.bookmarksDemo() || this.firestoreReadService.allContentLinks_map().get(this.content().id)?.size)
  )
  showTags = computed(() => {
    const count = this.firestoreReadService.allContentLinks_map().get(this.content().id)?.size || 0
    return this.showTag() && count > 9
  })

  styles = computed(() => this.editorContent().id === this.content().id ? this.editingStyles() : this.contentStyles())

  contentStyles = computed(() => this.timelineService.getStyles(this.content()))
  editingStyles = computed(() => this.timelineService.getStyles(this.editorContent()))
  isSvgImage = computed<boolean>(
    () => !!Object.values(this.content().rows).find((field) => !!(field as Image).svgGraphics),
  )
  interactiveContent = computed(() => this.content().status.showInteractiveDetails)

  showDetails = signal(false)
  showEditor = signal(false)
  showNewContentEditor = signal(false)

  flagRotationStyle = computed(() => {
    const rotation = !this.showEditor
      ? this.content().settings.rotation.deg
      : this.editorContent()?.settings.rotation.deg || 0
    return "rotate(" + -rotation + "deg)"
  })

  /**
   * detect double-clicks
   */
  clickDebounce = debounceSignal(0, 600)
  clickCount = signal<number>(0)
  hasFocus = signal(false)
  showLeftBorder = computed(() => {
    const mouseover = this.mouseEvent()?.type === "mouseover"
    return this.interactiveMode() && this.interactiveContent() && (mouseover || (this.selected() && this.hasFocus()))
  })
  selectedBoxShadow = computed(() => {
    const mouseover = this.mouseEvent()?.type === "mouseover"
    return !this.interactiveMode() && (mouseover ||
    (this.selected() && this.hasFocus()))
      ? "0 0 4px 4px rgba(255, 0, 0, 0.9)"
      : undefined

    /*
        const redShadow = "0px 0px 4px 4px rgba(255, 0, 0, 0.9)"
        if (this.selected()) {
          return redShadow
        }
        const eventType = this.mouseEvent()?.type
        switch (eventType) {
          case "mouseover":
            return redShadow
        }
        return undefined
    */
  })

  newContent = signal<Content | undefined>(undefined)

  // constructor() {
  //   effect(() => {
  //     if (this.selected() && this.routeService.fragments()) {
  //       this.inputElementRef()?.nativeElement.focus()
  //       const absoluteX = this.content().settings.position.left.px
  //       const absoluteY = this.content().settings.position.top.px
  //
  //       const left = absoluteX - window.innerWidth / 2
  //       const top = absoluteY - window.innerHeight / 2
  //
  //       window.scrollTo({ left, top, behavior: "smooth" })
  //     }
  //   })
  // }

  cdkDragStarted() {
    this.dragging.set(true)
  }

  cdkDragEnded(event: CdkDragEnd, content: Content): void {
    this.dragging.set(false)
    const left = {
      // px: Math.min(3000 - 100, Math.max(0, position.left.px + event.distance.x) || 0) || 0
      px: this.content().settings.position.left.px + event.distance.x,
    }
    const top = {
      // px: Math.min(3000 - 100, Math.max(0, position.top.px + event.distance.y) || 0) || 0
      px: this.content().settings.position.top.px + event.distance.y,
    }
    console.log(left, top)

    this.editorService.queueEditor({
      ...content,
      settings: {
        ...content.settings,
        position: {
          ...content.settings.position,
          left,
          top,
        },
      },
    })
    event.source._dragRef.reset()
  }

  clickContent(event: Event): void {
    event.preventDefault() // needed to prevent keyboard enter event from propagating into the dialog
    if (this.showEditor()) {
      this.deselect()
      return
    }
    const content = this.content()
    // if (this.clearContentEditor()) return

    this.timelineService.selectItem(this.content().id)

    if (this.editMode()) {
      this.showEditor.set(true)
      // this.routeService.changeFragment("edit/" + this.content().id)
    }
    if (this.interactiveMode() && this.interactiveContent()) {
      this.showDetails.set(true)
      // this.routeService.changeFragment("view/" + this.content().id)
    }
  }

  clickSelected() {

    // const clickCount = this.clickCount()
    // this.clickCount.set(clickCount + 1)
    // this.clickDebounce.set(clickCount + 1)
  }

  singleClick() {
    this.deselect()
  }

  doubleClick() {
    // this.timelineService.scale.set(1)
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    const fragment = "edit/" + this.content().id
    this.routeService.changeFragment(fragment)
  }

  /*
    showContentEditor(event: Event): void {
      event.stopPropagation()
      if (this.clearContentEditor()) return
      this.timelineService.scale.set(1)
      const fragment = "edit/" + this.content().id
      this.router.navigate([], { queryParamsHandling: "preserve", fragment })
    }
  */

  deselect() {
    this.timelineService.deselectItem()
  }

  changeSelectedId(event: Event, selectedId: string) {
    this.timelineService.selectItem(selectedId)
  }

  createNewContent() {
    const user = {
      id: this.profileService.userId(),
      name: this.profileService.userName(),
      role: this.profileService.userRole(),
      region: this.profileService.region(),
    }

    if (!(user.id && user.name && user.role)) {
      this.routeService.changeRoute([], { p: "my-account-login", s: "" })
      return
    }

    if (this.profileService.profileStatus().progress === ProfileProgress.INCOMPLETE) {
      this.routeService.changeRoute([], { p: "my-account-login", s: "" })
      // this.routeService.changeRoute([], { p: "my-account-profile", s: "" })
      return
    }

    let newContent = this.firestoreReadService
      .newContent(
        user,
        [ContentType.TIMELINE, ContentType.CONTRIBUTED],
        Classification.CONTRIBUTED,
      )
    newContent = {
      ...newContent,
      settings: {
        ...newContent.settings,
        links: [this.content().id],
        position: {
          layer: 5,
          left: { px: 777 },
          top: { px: 555 },
        },
      },
    }
    this.newContent.set(newContent)
    this.showNewContentEditor.set(true)
  }

  protected readonly faTag = faTag
  protected readonly faTags = faTags
}
