import { Component, computed, inject, input, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { FieldType, Image, SectionEnum } from "../fields.type"
import {
  SvgGraphic,
  SvgGraphics,
  TimelineSvgGraphicsService,
} from "../../../pages/timeline/timeline.svg-graphics.service"
import { ImageCropperFormComponent } from "./image-cropper-form.component"
import { EditorContext, EditorService } from "../../../editor/services/editor.service"
import { StorageService } from "@shared"
import { SaveIconComponent } from "../../save-icon.component"
import { UiTextareaComponent } from "ui/textarea"
import { NgOptimizedImage } from "@angular/common"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    FormsModule,
    ImageCropperFormComponent,
    SaveIconComponent,
    UiInheritDirective,
    UiTextareaComponent,
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    <div style="display: flex; flex-wrap: wrap; gap: 10px; align-items: center">
      <div role="tablist" class="tabs tabs-lifted">
        <a
          role="tab"
          class="font-bold"
          [class]="tabIndex() === 0 ? 'tab tab-active' : 'tab'"
          style="white-space: nowrap"
          (click)="_tabIndex.set(0)"
        >
          Upload Image
        </a>
        <div class="tab-content bg-base-100 border-base-300 rounded-box p-6">
          <div class="column" style="align-content: stretch">
            <div class="row">
              <lib-ui-textarea
                [rows]="1"
                placeholder="alt text - required"
                [value]="imageAlt()"
                (changes)="changeAlt($event)"
              />
              <e2e-save-icon [modelChange]="imageAltModel()" />
            </div>
            <e2e-image-cropper-form [rowId]="rowId()" />
          </div>
        </div>

        @if (adminContext()) {
          <a
            role="tab"
            class="font-bold"
            [class]="tabIndex() === 1 ? 'tab tab-active' : 'tab'"
            style="white-space: nowrap"
            (click)="_tabIndex.set(1)"
          >
            Select SVG
          </a>
          <div class="tab-content bg-base-100 border-base-300 rounded-box p-6">
            @defer (when tabIndex() === 1) {
              <div class="row" style="gap: 0">
                @for (svg of svgGraphics; track svg.label) {
                  <div
                    style="outline: 1px solid black; cursor: pointer; outline-offset: 0"
                    [style.box-shadow]="selectedSvg() === svg.label || hover() === svg.label ? 'inset 0px 0px 4px 4px rgba(255, 0, 0, 0.9)' : undefined"
                    (mouseover)="hover.set(svg.label)"
                    (mouseout)="hover.set(null)"
                    (click)="changeSvg(svg.label)"
                  >
                    <img style="width: 80px; height: 80px" width="80" height="80" [ngSrc]="svg.fileName" alt="" />
                  </div>
                }
              </div>
            }
          </div>
        }

      </div>
    </div>
  `,
  selector: "e2e-image-edit",
})
export class ImageEditComponent {
  private storageService = inject(StorageService)
  private editorService = inject(EditorService)
  private svgGraphicsService = inject(TimelineSvgGraphicsService)

  rowId = input.required<string>()
  context = input<EditorContext | undefined>(undefined)

  adminContext = computed(() => this.context() === "admin")

  _tabIndex = signal<number | undefined>(undefined)
  tabIndex = computed<number>(() => {
    const tabIndex = this._tabIndex()
    return tabIndex !== undefined
      ? tabIndex
      : (this.editorService.editorContent()?.rows[this.rowId()] as Image)?.svgGraphics
        ? 1
        : 0
  })
  hover = signal<SvgGraphic["label"] | null>(null) // : SvgGraphic["label"] | null = null
  imageAlt = computed(() => (this.editorService.editorContent()?.rows[this.rowId()] as Image)?.alt || "")

  private _selectedSvg = signal<SvgGraphics | undefined>(undefined) // content.rows[this.rowId].svgGraphics
  selectedSvg = computed(
    () => this._selectedSvg() || (this.editorService.editorContent()?.rows[this.rowId()] as Image).svgGraphics,
  )

  svgGraphics: SvgGraphic[] = this.svgGraphicsService.svgGraphics.map((svgGraphic) => ({
    ...svgGraphic,
    fileName: svgGraphic.fileName,
  }))
  imageAltModel = signal<number | undefined>(undefined)

  changeSvg(svgGraphics: SvgGraphics) {
    const content = this.editorService.editorContent()
    if (content?.rows[this.rowId()].field === FieldType.IMAGE) {
      const imageRow = content.rows[this.rowId()] as Image

      console.log("svgGraphics", svgGraphics)
      console.log("this.selectedSvg()", this.selectedSvg())

      if (this.selectedSvg() === svgGraphics) {
        console.log("this.selectedSvg() === svgGraphics")
        imageRow.svgGraphics = null
        this._selectedSvg.set(undefined)
      }
      if (this.selectedSvg() !== svgGraphics) {
        imageRow.svgGraphics = svgGraphics
        this._selectedSvg.set(svgGraphics)
      }
      this.hover.set(null)

      this.editorService.queueEditor({
        ...content,
        rows: {
          ...content.rows,
          [this.rowId()]: {
            ...imageRow,
            filePath: "",
            fileType: "",
          },
        },
      })
    }
  }

  changeAlt(alt: string) {
    this.imageAltModel.set(Date.now())
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        rows: {
          ...content.rows,
          [this.rowId()]: {
            ...content.rows[this.rowId()],
            alt,
            section: SectionEnum.IMAGE,
          },
        },
      })
    }
  }
}
