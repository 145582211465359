import { Component, computed, EventEmitter, inject, output, Output } from "@angular/core"
import { BookmarkComponent } from "@shared"
import { FirestoreReadService } from "../content/firestore-read.service"
import { ProfileService } from "../profile/profile.service"
import { UiInheritDirective } from "ui/inherit"
import { UiIconComponent } from "ui/icon"
import { faTag } from "@fortawesome/pro-solid-svg-icons"

@Component({
  imports: [
    UiInheritDirective,
    BookmarkComponent,
    UiIconComponent,
  ],
  standalone: true,
  template: `
    <button
      class="btn bg-primary text-primary-content rounded-full uppercase w-full"
      (click)="createNewContent.emit()"
    >
      <lib-ui-icon [icon]="faTag"/>
      Propose a Story, Reflection or History
    </button>
  `,
  selector: "e2e-share-your-story",
})
export class ShareYourStoryComponent {
  private firestoreReadService = inject(FirestoreReadService)
  private profileService = inject(ProfileService)

  createNewContent = output<void>()

  myContentCount = computed(
    () =>
      this.firestoreReadService
        .arrayFromStories()
        .filter((story) => story.created.userId === this.profileService.userId()).length,
  )
  protected readonly faTag = faTag
}
