import { Component, computed, inject } from "@angular/core"
import { RegionEnum } from "../../../../regions/region.model"
import { RouteService } from "../../../../services/route.service"
import { ClevelandOhRegion } from "../../../../regions/cleveland-oh.region"
import { BaltimoreMdRegion } from "../../../../regions/baltimore-md.region"
import { DetroitMiRegion } from "../../../../regions/detroit-mi.region"
import { NgOptimizedImage } from "@angular/common"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    @if (brandHeader(); as header) {
        <div
          class="column justify-center items-center"
          style="flex: 1; padding-bottom: 100px"
          >
          @if (header.title) {
            <div class="text-6xl font-black">
              {{ header.title }}
            </div>
          }
          @if (header.subTitle) {
            <div class="text-md">
              {{ header.subTitle }}
            </div>
          }
          @if (header.image && header.image.src) {
            <div
              class="p-2 rounded-lg"
              [style.width]="header.style.width"
              >
              <img [ngSrc]="header.image.src" [alt]="header.image.altText" fill style="position: relative"/>
            </div>
          }
        </div>
      }
      `,
  selector: "e2e-intro-brand-header",
})
export class IntroBrandHeaderComponent {
  private routeService = inject(RouteService)
  private clevelandOhRegion = inject(ClevelandOhRegion)
  private baltimoreMdRegion = inject(BaltimoreMdRegion)
  private detroitMiRegion = inject(DetroitMiRegion)

  brandHeader = computed(() => {
    switch (this.routeService.region()) {
      case RegionEnum.BALTIMORE_MD:
        return this.baltimoreMdRegion.brandHeader()
      case RegionEnum.CLEVELAND_OH:
        return this.clevelandOhRegion.brandHeader()
      case RegionEnum.DETROIT_MI:
        return this.detroitMiRegion.brandHeader()
      default:
        return undefined
    }
  })

}
